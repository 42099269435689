<template>
  <router-view/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  background-color: #f5f5f7;
}
</style>